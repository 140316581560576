import React from 'react';
import styles from './HomepageTabPanelCta.module.scss';
import { HomepageTabPanelCtaProps } from './HomepageTabPanelCta.props';
import CustomButton from '@atoms/CustomButton/CustomButton';

const HomepageTabPanelCta = ({ cta, colorVariant }: HomepageTabPanelCtaProps) => {
  return (
    <CustomButton
      // color={colorVariant === 'light' ? 'secondary' : 'secondaryOnDark'}
      color="primary"
      href={cta.url}
      rel={cta.rel}
      target={cta.target}
      component="a"
      className={styles.CtaButton}
      variant="large"
      text={cta.label}
      fullWidth={false}
      external={cta.isExternal}
    />
  );
};

export default HomepageTabPanelCta;
